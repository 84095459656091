import {
    createRouter,
    createWebHashHistory
} from 'vue-router'

import { ElMessage } from 'element-plus'

const routes = [{
        path: '/',
        redirect: '/home',
        component: () => import('@/components/viewCommon.vue'),
        children: [{
                path: 'home',
                name: 'Home',
                component: () => import('@/views/index.vue'),
                meta: {
                    title: '岐黄书简中医专病系列知识库'
                }
            },
            {
                path: 'path',
                name: 'Path',
                component: () => import('@/views/major/aboutPath.vue'),
                meta: {
                    title: '临床路径'
                }
            },
            {
                path: 'medicine',
                name: 'Medicine',
                component: () => import('@/views/major/medicine.vue'),
                meta: {
                    title: '相关中药'
                }
            },
            {
                path: 'medDetail',
                name: 'MedDetail',
                component: () => import('@/views/major/medDetail.vue'),
                meta: {
                    title: '相关中药'
                }
            },
            {
                path: 'prescription',
                name: 'Prescription',
                component: () => import('@/views/major/prescription.vue'),
                meta: {
                    title: '相关方剂'
                }
            },
            {
                path: 'preDetail',
                name: 'PreDetail',
                component: () => import('@/views/major/preDetail.vue'),
                meta: {
                    title: '相关方剂'
                }
            },
            {
                path: 'literature',
                name: 'Literature',
                component: () => import('@/views/major/literature.vue'),
                meta: {
                    title: '文献典籍'
                }
            },
            {
                path: 'yl',
                name: 'yl',
                component: () => import('@/views/major/yl.vue'),
                meta: {
                    title: '各家医论'
                }
                
            },
            {
                path: 'ylList',
                name: 'ylList',
                component: () => import('@/views/major/ylList.vue'),
                meta: {
                    title: '各家医论'
                }
            },
            {
                path: 'yian',
                name: 'yian',
                component: () => import('@/views/major/yian.vue'),
                meta: {
                    title: '医案集锦'
                }
                
            },
            {
                path: 'yaList',
                name: 'yaList',
                component: () => import('@/views/major/yaList.vue'),
                meta: {
                    title: '医案集锦'
                }
            },
            {
                path: 'fzAnalysis/:id',
                name: 'fzAnalysis',
                component: () => import('@/views/major/analysis/index.vue'),
                meta: {
                    title: '语义解析'
                }
            },
            {
                path: 'zj',
                name: 'zj',
                component: () => import('@/views/major/zj.vue'),
                meta: {
                    title: '针灸推拿'
                }
                
            },
            {
                path: 'zjList',
                name: 'zjList',
                component: () => import('@/views/major/zjList.vue'),
                meta: {
                    title: '针灸推拿'
                }
            },
            {
                path: 'yaoshan',
                name: 'yaoshan',
                component: () => import('@/views/major/yaoShan.vue'),
                meta: {
                    title: '康养'
                }
            },
            {
                path: 'ysList',
                name: 'ysList',
                component: () => import('@/views/major/ysList.vue'),
                meta: {
                    title: '康养'
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: '/home'
    }
]

const router = new createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async(to, from, next) => {
    window.document.title = to.meta.title == undefined?'岐黄书简中医专病系列知识库':to.meta.title
    const isLogin = localStorage.getItem('username')
    if(to.meta.isRequire && !isLogin && to.name !== 'Login') {
        ElMessage({
            showClose: true,
            message: "查看当前页面需要登录",
            type: "error", 
        })
        next({ name: 'Login' })
    }
    next()
})

export default router