// 整理树节点变成 el-tree 的结构
export function test(ary, data) {

  var data = data ? data : (function (ary) {
    var tempAry = [];
    for (var i = 0, len = ary.length; i < len; i++) {
      if (!!ary[i].parentSemanticId == false) {
        var obj = {
          termName: ary[i].termName,
          semanticId: ary[i].semanticId,
          parentSemanticId: ary[i].parentSemanticId,
        };
        tempAry.push(obj);
      }
    }
    return tempAry;
  }(ary));

  var temp = 0;
  if (data.constructor == Array) {
    for (var i = 0, len = data.length; i < len; i++) {
      for (var j = 0, lenA = ary.length; j < lenA; j++) {
        if (ary[j].parentSemanticId == data[i].semanticId) {
          var obj = {
            termName: ary[j].termName,
            semanticId: ary[j].semanticId,
            parentSemanticId: ary[j].parentSemanticId
          };
          data[i].child = data[i].child || [];
          data[i].child.push(obj);
          temp++;
        }
      }
    }
  }

  if (temp > 0) {
    if (data.constructor == Array) {
      for (var n = 0, lenB = data.length; n < lenB; n++) {
        data[n].child = test(ary, data[n].child ? data[n].child : []);
        if (data[n].child.length == 0) {
          delete data[n].child;
        }
        // delete data[n].semanticId;
      }
    }
  } else {
    for (var n = 0, lenB = data.length; n < lenB; n++) {
      //   delete data[n].semanticId;
    }

  }
  return data;

}

// 将数组通过某一个属性转化成树形结构
export function toTree(data) {
  let result = []
  if (!Array.isArray(data)) {
    return result
  }
  data.forEach(item => {
    delete item.children;
  });
  let map = {};
  data.forEach(item => {
    map[item.semanticId] = item;
  });
  data.forEach(item => {
    let parent = map[item.parentSemanticId];
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      result.push(item);
    }
  });
  return result;
}

// 对数据进行编码
export function encode(data) {
  return encodeURIComponent(data)
}

// 获取cookie token
export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end))
    }

  }
  return ""
}


// 将时间戳转化为日期格式
export function getLocalTime(nS) {
  //将时间戳（十三位时间搓，也就是带毫秒的时间搓）转换成时间格式
  // d.cTime = 1539083829787
  let date = new Date(nS);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  date = year + '-' + month + '-' + day;
  return date;
}


// 去除字符串中的各种括号
export function repStr(str) {
  // return str.replace(/\[|\]|\(|\)|【|】|{|}|<|>|（|）|《|》/g, '');
  return str
}

function formatTen(num) {
  return num > 9 ? (num + "") : ("0" + num);
}
export function formatDate(date) {
  var date = new Date(date)
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  return year + "-" + formatTen(month) + "-" + formatTen(day) + " " + formatTen(hour) + ":" + formatTen(minute) + ":" + formatTen(second);
}

// 滚动到顶部
export function toTop() {
  let top = document.documentElement.scrollTop || document.body.scrollTop;
  // 实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop =
      document.documentElement.scrollTop =
      top -=
      50;
    if (top <= 0) {
      clearInterval(timeTop);
    }
  }, 10);
}

// 字符串截取参数 1：原字符串 2：从末尾的哪一个开始截取 3: 判断是否存在的字符串
export function returnSubString(str, index, isExistStr) {
  let subString = str.substr(str.length - index)
  if (subString == isExistStr) {
    return str.substr(0, str.length - index)
  }
  return str
}

// 将数组按照某个共同字段分组
export function groupArray(arr, property) {
  let result = []
  if (arr.length == 0) return []
  if (arr.length == 1) return [arr]
  let map = new Map()
  for (let i = 0; i < arr.length; i++) {
    let prop = arr[i][property]
    if (!map.has(prop)) {
      map.set(prop, [arr[i]])
    } else {
      let propVal = map.get(prop)
      propVal.push(arr[i])
    }
  }
  for (var [key, value] of map) {
    result.push(value)
  }
  return result
}

// 参数分割
export function splitParams (str, mark) {
  if(!str) return []
  return str.split(mark)
}
// 参数拼接
export function joinParams (str, mark) {
  if(!str) return []
  return str.join(mark)
}

// 去除数组中的空字符串
export function removeEmptyStr(arr, mark) {
  return arr.map(item => item[mark]).filter(item => item)
}

// 判断字符串中是否含有 空格 和 + 
export function checkSearchVal(searchVal) {
  const rep = /\+|\s+/g
  return !rep.test(searchVal)
}

// 节流 => 一定时间内只调用一次函数
export function throttle(event, time){
  let timer = null
  return function() {
      if(!timer) {
          timer = setTimeout(() => {
              event()
              timer = null
          }, time)
      }
  }
}

export function isAdminFun(str) {
  let arr = str.split(",")
  let index =  arr.findIndex((item) => item === 'Admin')
  let boo = index !== -1
  return boo
}