import async from "./http.js";
import { encode } from "@/utils";
let api = process.env.VUE_APP_BASE_API;

// 更新用户信息
export function modifyUser(data) {
  return async(api + "/sys/modifyUser", data, "post").then((data) => data);
}
// 获取用户信息
export function getModifyUser(data) {
  return async(api + "/user/getModifyUser", data).then((data) => data);
}
// 修改密码
export function modifyUserPass(data) {
  return async(api + "/user/modifyUserPass", data, "post").then((data) => data);
}
// 添加用户
export function addUser(data) {
  return async(api + "/sys/addUser", data, "post").then((data) => data);
}
// 删除用户
export function delUser(data) {
  return async(api + "/sys/delUser", data, "post").then((data) => data);
}
// 获取用户管理列表
export function findUsers(data) {
  return async(api + "/sys/findUsers", data).then((data) => data);
}
// 登录
export function login(data) {
  return async(api + "/login", data, "post").then((data) => data);
}

// 登录成功以后获取用户名
export function getUser() {
  return async(api + "/user/getUser").then((data) => data);
}

// 获取专病
export function getSpecial() {
  return async(api + "/getSpecial").then((data) => data);
}
// 获取所有下级
export function getChildTree(data) {
  return async(api + "/gb/getChildTree", data).then((data) => data);
}
// 获取详情
export function getPublicGb(data) {
  return async(api + "/public/getPublicGb", data).then((data) => data);
}
// 获取相关疾病
export function findGbRelated(data) {
  return async(api + "/gb/findGbRelated", data).then((data) => data);
}
// 自动补全
export function getSearch(data) {
  return async(api + "/gb/getSearch", data).then((data) => data);
}
// 首页医案列表
export function findGbSourceYanList(data) {
  return async(api + "/supper/findGbSourceYanList", data).then((data) => data);
}
// 医案列表
export function findGbSourceYanWithPage(data) {
  return async(api + "/supper/findGbSourceYanWithPage", data).then(
    (data) => data
  );
}
// 获取医案详情
export function getGbSourceYan(data) {
  return async(api + "/supper/getGbSourceYan", data).then((data) => data);
}
// 获取相关证候
export function findJbAboutZh(data) {
  return async(api + "/supper/findJbAboutZh", data).then((data) => data);
}
// 获取相关治法
export function findJbAboutZf(data) {
  return async(api + "/supper/findJbAboutZf", data).then((data) => data);
}
// 获取相关路径
export function getGuidDetailPath(data) {
  return async(api + "/getGuidDetailPath", data).then((data) => data);
}
// 获取相关路径详情
export function getGuidDetailBing(data) {
  return async(api + "/getGuidDetailBing", data).then((data) => data);
}
// 中药方剂检索
export function searchTermsPro(data) {
  return async(api + "/SqlTcmTerms/searchTermsPro", data).then((data) => data);
}
// 中药方剂检索详情
export function searchTermDetail(data) {
  return async(api + "/SqlTcmTerms/searchTermDetail", data).then(
    (data) => data
  );
}
// 详情页功能主治查看显示
export function searchGnZhuzhiesSemantic(data) {
  return async(api + "/SqlTcmTerms/searchGnZhuzhiesSemantic", data).then(
    (data) => data
  );
}
// 文献全局检索
export function globalSearch(data) {
  return async(api + "/PCLiterature/globalSearch", data).then((data) => data);
}
// 文献全局检索 - 书籍
export function globalSearchLiterature(data) {
  return async(api + "/PCLiterature/globalSearchLiterature", data).then(
    (data) => data
  );
}
// 获取文献简介
export function getLiteratureBrief(data) {
  return async(api + "/PCLiterature/getLiteratureBrief", data).then(
    (data) => data
  );
}
// 文章内容详情（实现回溯、包纳下级）
export function globalSearchTxt(data) {
  return async(api + "/PCLiterature/globalSearchTxt", data).then(
    (data) => data
  );
}
// 输入引导
export function findSearch(searchVal, searchType) {
  return async(api + "/PCLiterature/findSearch", {
    searchVal: searchVal ? encode(searchVal) : "",
    searchType: searchType ? encode(searchType) : "",
  }).then((data) => data);
}

// 专业版文献检索——全文阅读检索懒加载
export function lazyLoad(literatureName, originalId) {
  return async(api + "/PCLiterature/search", {
    literatureName: literatureName ? encode(literatureName) : "",
    originalId: originalId ? encode(originalId) : "",
  }).then((data) => data);
}
// 添加专病方药关联
export function addJbTermPro(data) {
  return async(api + "/SqlTcmTerms/addJbTermPro", data).then((data) => data);
}
// 删除专病方药关联
export function delJbTermPro(data) {
  return async(api + "/SqlTcmTerms/delJbTermPro", data).then((data) => data);
}
// 方药管理添加检索
export function searchTermsPro2(data) {
  return async(api + "/SqlTcmTerms/searchTermsPro2", data).then((data) => data);
}
// 添加医案
export function addGbSourceYan(data) {
  return async(api + "/supper/addGbSourceYan", data, "post").then(
    (data) => data
  );
}
// 更新医案
export function updateGbSourceYan(data) {
  return async(api + "/supper/updateGbSourceYan", data, "post").then(
    (data) => data
  );
}
// 删除医案
export function delGbSourceYan(data) {
  return async(api + "/supper/delGbSourceYan", data, "post").then(
    (data) => data
  );
}
// 查询相关病分页
export function findGbRelatedPage(data) {
  return async(api + "/sys/findGbRelated", data).then((data) => data);
}
// 添加相关病
export function addGbRelated(data) {
  return async(api + "/sys/addGbRelated", data, "post").then((data) => data);
}
// 修改相关病
export function updateGbRelated(data) {
  return async(api + "/sys/updateGbRelated", data, "post").then((data) => data);
}
// 删除相关病
export function delGbRelated(data) {
  return async(api + "/sys/delGbRelated", data, "post").then((data) => data);
}
// 添加相关证候
export function saveGbBzf(data) {
  return async(api + "/sys/saveGbBzf", data, "post").then((data) => data);
}
// 删除相关证候
export function delGbBz(data) {
  return async(api + "/sys/delGbBz", data, "post").then((data) => data);
}
// 上传历史
export function findDataHis(data) {
  return async(api + "/sys/findDataHis", data).then((data) => data);
}
// 康养列表
export function findGbSourceFoodList(data) {
  return async(api + "/supper/findGbSourceFoodList", data).then((data) => data);
}
// 康养列表分页
export function findGbSourceFoodWithPage(data) {
  return async(api + "/supper/findGbSourceFoodWithPage", data).then(
    (data) => data
  );
}
// 添加康养
export function addGbSourceFood(data) {
  return async(api + "/supper/addGbSourceFood", data, "post").then(
    (data) => data
  );
}
// 删除康养
export function delGbSourceFood(data) {
  return async(api + "/supper/delGbSourceFood", data, "post").then(
    (data) => data
  );
}
// 修改康养
export function updateGbSourceFood(data) {
  return async(api + "/supper/updateGbSourceFood", data, "post").then(
    (data) => data
  );
}
// 获取康养
export function getGbSourceFood(data) {
  return async(api + "/supper/getGbSourceFood", data).then((data) => data);
}
// 医家联想输入
export function getAuthorSearch(data) {
  return async(api + "/supper/getAuthorSearch", data).then((data) => data);
}
// 出处联想输入
export function getSourceSearch(data) {
  return async(api + "/supper/getSourceSearch", data).then((data) => data);
}
// 获取术语定义
export function getGbSynonyms(data) {
  return async(api + "/sys/getGbSynonyms", data).then((data) => data);
}
// 添加术语定义
export function addGbSynonyms(data) {
  return async(api + "/sys/addGbSynonyms", data, "post").then((data) => data);
}
// 删除术语定义
export function delGbSynonyms(data) {
  return async(api + "/sys/delGbSynonyms", data, "post").then((data) => data);
}
// 转系统医案
export function chgGbSourceYan(data) {
  return async(api + "/supper/chgGbSourceYan", data).then((data) => data);
}

// 语义检索知识图谱初始化
export function knowledgeMapLnitializeGb(data) {
  return async(api + "/SqlTcmTerms/knowledgeMapLnitializeGb", data).then(
    (data) => data
  );
}

// 专业版二次方剂统计分析
export function fangJiCount(
  keyWords,
  termNameCN,
  semanticSource,
  gongnengsDetail,
  zhuzhisDetail,
  bybj,
  peiwusDetail,
  xiyisDetail,
  searchType,
  semantic,
  analyzeField,
  pageNo,
  pageSize
) {
  return async(api + "/SqlTcmTerms/searchTermsProAnalyzeCount", {
    keyWords: keyWords ? encode(keyWords) : "",
    termNameCN: termNameCN ? encode(termNameCN) : "",
    semanticSource: semanticSource ? encode(semanticSource) : "",
    gongnengsDetail: gongnengsDetail ? encode(gongnengsDetail) : "",
    zhuzhisDetail: zhuzhisDetail ? encode(zhuzhisDetail) : "",
    bybj: bybj ? encode(bybj) : "",
    peiwusDetail: peiwusDetail ? encode(peiwusDetail) : "",
    xiyisDetail: xiyisDetail ? encode(xiyisDetail) : "",
    searchType: encode(searchType),
    semantic: encode(semantic),
    analyzeField: encode(analyzeField),
    pageNo: encode(pageNo),
    pageSize: encode(pageSize),
    allowInvert: "FALSE",
  }).then((data) => data);
}
// 专业版二次中药统计分析
export function herbalCount(
  keyWords,
  termNameCN,
  gongnengsDetail,
  zhuzhisDetail,
  tastesDetail,
  propertiesDetail,
  peiwusDetail,
  searchType,
  semantic,
  analyzeField,
  pageNo,
  pageSize
) {
  return async(api + "/SqlTcmTerms/searchTermsProAnalyzeCount", {
    keyWords: keyWords ? encode(keyWords) : "",
    termNameCN: termNameCN ? encode(termNameCN) : "",
    gongnengsDetail: gongnengsDetail ? encode(gongnengsDetail) : "",
    zhuzhisDetail: zhuzhisDetail ? encode(zhuzhisDetail) : "",
    tastesDetail: tastesDetail ? encode(tastesDetail) : "",
    propertiesDetail: propertiesDetail ? encode(propertiesDetail) : "",
    peiwusDetail: peiwusDetail ? encode(peiwusDetail) : "",
    searchType: encode(searchType),
    semantic: encode(semantic),
    analyzeField: encode(analyzeField),
    pageNo: encode(pageNo),
    pageSize: encode(pageSize),
    allowInvert: "FALSE",
  }).then((data) => data);
}
// 专业检索药对分析
export function searchTermsProHerbalPair(
  keyWords,
  termNameCN,
  gongnengsDetail,
  zhuzhisDetail,
  peiwusDetail,
  semanticSource,
  bybj,
  xiyisDetail,
  pageNo,
  pageSize
) {
  return async(api + "/SqlTcmTerms/searchTermsProHerbalPair", {
    keyWords: keyWords ? encode(keyWords) : "",
    termNameCN: termNameCN ? encode(termNameCN) : "",
    gongnengsDetail: gongnengsDetail ? encode(gongnengsDetail) : "",
    zhuzhisDetail: zhuzhisDetail ? encode(zhuzhisDetail) : "",
    peiwusDetail: peiwusDetail ? encode(peiwusDetail) : "",
    semanticSource: encode(semanticSource),
    bybj: encode(bybj),
    xiyisDetail: encode(xiyisDetail),
    pageNo: encode(pageNo),
    pageSize: encode(pageSize),
  }).then((data) => data);
}
// 功能主治病机联想
export function findTermNameX(data) {
  return async(api + "/gb/findTermNameX", data).then((data) => data);
}
