import {
    createApp
} from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/scss/element/index.scss'
import * as Icons from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/scss/base.scss'

// import BaiduMap from 'vue-baidu-map'


let app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn,
})
// app.use(BaiduMap, {
//     ak: 'IKymXU4lY1VxdkBC8KtlcHxtxWga63Yq'
// })
for (let iconName in Icons) {
    app.component(iconName, Icons[iconName])
}


// 注册一个全局自定义指令 `v-focus`
app.directive('focus', {
    // 当被绑定的元素挂载到 DOM 中时……
    mounted(el) {
        // 聚焦元素
        el.focus()
    }
})

app.use(router).use(store).mount('#app')