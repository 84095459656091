<template>
  <div class="min-height d-flex">
    <div class="flex-1 main-bg">
      <router-view></router-view>
    </div>
    <vFooter/>
    <el-backtop :right="40" :bottom="40" />
  </div>
</template>

<script>
import { getUser } from "@/fetch/api";
import { useRouter } from "vue-router";
import vFooter from '@/components/Footer.vue'
export default {
  components: {
    vFooter
  },
  setup() {
    const router = useRouter();
    getUser().then((data) => {
      if(!data) {
        router.push("/login");
      } else {
        localStorage.setItem("username", data.realName);
        localStorage.setItem("roles", data.roles);
      }
    });
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
.main-bg{
  background: #fff url("@/assets/images/index/bg.png") bottom right no-repeat;
  background-size: 1920px 572px;
}
html,
body {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}
.min-height{
  flex-direction: column;
  height: 100vh;
}
</style>
