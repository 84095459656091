<template>
  <el-footer>
    <!-- <div class="d-flex jc-center">
      <div class="index_foot_dayi"></div>
      <div class="index_foot_erwei">
        <h2>岐黄书简公众号</h2>
        <p>关注我们，手机使用更方便</p>
      </div>
    </div> -->
    <div>
      相关中药，方剂，文献和医案集锦，仅供临床医生参考，具体用药请根据实际情况自行斟酌。
    </div>
    <p class="my-1">copyright © 2022 上海岐黄信息技术有限公司 TEL：021-66695530 66695526</p>
    <p>
      <span>沪ICP备18038117号 &nbsp;</span>
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602003001"
        >沪公网安备 31010602003001号</a
      >
    </p>
  </el-footer>
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    const footerStyle = reactive({
      position: "fixed",
      left: 0,
      bottom: 0,
    });
    return {
      footerStyle,
    };
  },
};
</script>

<style lang="scss" scoped>
.el-footer {
  width: 100%;
  background: #fff;
  border-top: 1px solid #fff;
  font-size: 12px;
  height: auto;
  padding: 20px;
  text-align: center;
  a {
    color: #2d2d2d;
    text-decoration: none;
  }
  .index_foot_erwei {
    height: 150px;
    background-color: #fff;
    width: 310px;
    background: url("@/assets/images/index/index_foot_er.png") 18px 25px
      no-repeat;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    p {
      font-size: 14px;
      color: #2d2d2d;
      margin-left: 140px;
      margin-top: 5px;
    }
    h2 {
      font-size: 24px;
      color: #2d2d2d;
      margin-left: 140px;
      margin-top: 50px;
    }
  }
  .index_foot_dayi {
    height: 150px;
    width: 200px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background: url("@/assets/images/index/index_foot_dyjc.png") center
      no-repeat;
  }
}
</style>
