// import memberApi from '../fetch/majorSearch'
import { createStore } from "vuex"

const state = {
  // 中药
  medForm: {},
  medTableData: [],
  medTotal: null,
  medPageTotal: null,
  medPageNo: 1,
  medSemantic: true, // 专业版中药--语义检索开关
  resultMedData: ["检索结果"],
  resultMdIndex: 0, // 结果当前按钮索引值

  // 方剂
  fjForm: {},
  fjTableData: [],
  fjTotal: null,
  fjPageTotal: null,
  fjPageNo: 1,
  fjSemantic: true, // 专业版方剂--语义检索开关
  resultFjData: ["检索结果"],
  resultFjIndex: 0, // 结果当前按钮索引值

  clickNum: 0, // 点击触发知识图谱刷新

  // 会员
  memberForm: {},
  currentIndex: 0,
  currentTab: '术语',
  memberData: [],
  memberTotal: null,
  memerPageTotal: null,
  tabList: ["术语"],
  checkedValue: ["术语库"],
  memberPageNo: 1, // 会员页数

  // 笔记内置页笔记内容
  // 文献
  textareaNote: "", // 笔记内容
  isOpen: false, // 写笔记是否公开
  lineContent: '', // 划线部分
  // 中药
  medNote: "", // 笔记内容
  isMedOpen: false, // 写笔记是否公开
  // 方剂
  fjNote: "", // 笔记内容
  isFjOpen: false, // 写笔记是否公开


  // 登录术语检索
  tcmKeyWords: '',
  tcmPageNumber: 1,
  tcmData: [],

  // 未登录术语检索
  tcmValue: '',
  tcmPageNn: 1,
  tcmNoLoginData: [],

  // 个人中心索引值
  centerIndex: 0,

  // 医案列表
  yianForm: {},
  yianData: [],
  yianTotal: null,
  yianPageTotal: null,
  yianPageNo: 1,

  // 康养列表
  ysForm: {},
  ysData: [],
  ysTotal: null,
  ysPageTotal: null,
  ysPageNo: 1,
};


const mutations = {
  // 替换康养数据
  SETYS(state, data) {
    state.ysForm = data.ysForm;
    state.ysData = data.tableData.content;
    state.ysTotal = data.tableData.totalElements;
    state.ysPageNo = data.tableData.totalPages;
    if (state.ysData) {
      state.ysData.forEach((item, i) => {
        item.yianIndex = i
      })
    }
  },
  // 替换医案数据
  SETYIAN(state, data) {
    state.yianForm = data.yianForm;
    state.yianData = data.tableData.content;
    state.yianTotal = data.tableData.totalElements;
    state.yianPageTotal = data.tableData.totalPages;
    if (state.yianData) {
      state.yianData.forEach((item, i) => {
        item.yianIndex = i
      })
    }
  },
  // 替换中药数据
  SETMEDCINE(state, data) {
    state.medForm = data.medForm;
    state.medTableData = data.tableData.data;
    state.medTotal = data.tableData.totalSize;
    state.medPageTotal = data.tableData.totalPages;
    if (state.medTableData) {
      state.medTableData.forEach((item, i) => {
        item.medIndex = i
      })
    }
  },

  // 替换方剂数据
  SETFANGJI(state, data) {
    state.fjForm = data.fjForm;
    state.fjTableData = data.tableData.data;
    state.fjTotal = data.tableData.totalSize;
    state.fjPageTotal = data.tableData.totalPages;
    if (state.fjTableData) {
      state.fjTableData.forEach((item, i) => {
        item.fjIndex = i
      })
    }

  },
  // 替换会员数据
  SETMEMBER(state, data) {
    state.memberForm = data.memberForm;
    state.memberData = data.data.data;
    state.memberTotal = data.data.totalSize;
    state.memerPageTotal = data.data.totalPages;
    if (state.memberData) {
      state.memberData.forEach((item, i) => {
        item.memIndex = i
      })
    }

  },
  // 设置当前索引值
  SETCURRENT(state, data) {
    state.currentIndex = data.currentIndex;
    state.currentTab = data.currentTab;
    state.tabList = data.tabList;
    state.checkedValue = data.value;
  },
  // 清除会员数据
  CLEARMEMBER(state, data) {
    state.memberData = data.data;
  },
  SETFJFORM(state, data) {
    state.fjForm = data;
  },
  SETMEDTERM(state, data) {
    state.medForm = data;
  }
}

const actions = {
  // 请求国标树和分科树
  async getUser() {
    const data = await memberApi.getUser();
    localStorage.setItem('username', data.realName ? data.realName : '')
  }
}

const store = createStore({
  state,
  mutations,
  actions
})

export default store;
